<template>
  <div class="book-confirm" :show-indicators="false">
    <van-swipe class="swiper">
      <van-swipe-item>
        <van-image width="100%" height="100%" :src="info.thumb" lazy-load />
      </van-swipe-item>
    </van-swipe>
    <van-cell-group>
      <van-cell
        :title="info.title"
        size="large"
        :label="info.productTitle"
      />
      <van-cell title="产品单价" :value="`${ info.price || '-' }元`" size="large" />
      <van-cell title="可预约时间" :value="bookTimeText" size="large" />
    </van-cell-group>
    <div v-html="info.content"></div>
    <van-submit-bar :price="total" button-text="付款" @submit="onSubmit">
      <template #default>
        <van-stepper v-model="count" />
      </template>
    </van-submit-bar>
  </div>
</template>

<script>
import { getHotelInfo, createOrder, upWechatPay, checkPayResult } from '../api'
import { SESSION_NAME } from '../utils/login'
import { Dialog } from 'vant'
export default {
  name: 'book-confirm',
  data() {
    return {
      info: {
        hotelId: '',
        hotelCatid: '',
        title: '',
        productTitle: '',
        thumb: '',
        content: '',
        price: '',
        starttime: '',
        endtime: '',
        notime: '',
        pcatid: '',
        pid: ''
      },
      count: 1,
      isPaying: false,
      orderformid: '',
    }
  },
  methods: {
    onSubmit() {
      this.pay()
    },
    init() {
      const { hcatid, hid, pid } = this.$route.query
      getHotelInfo({ catid: hcatid, id: hid }).then(res => {
        const { title, product = [], catid, id } = res.data
        this.info.hotelCatid = catid
        this.info.hotelId = id
        this.info.title = title
        // 设置产品信息
        for (let i = 0; i < product.length; i++) {
          const productItem = product[i]
          
          if (productItem.id == pid) {
            const { title, price, content, starttime, endtime, notime, thumb, catid, id } = productItem
            this.info.productTitle = title
            this.info.price = price
            this.info.content = content
            this.info.notime = notime
            this.info.thumb = thumb
            this.info.starttime = starttime
            this.info.endtime = endtime
            this.info.notime = notime
            this.info.pcatid = catid
            this.info.pid = id
            break
          }
        }
      })
    },
    pay() {
      const toast = this.$toast.loading({
        duration: 0,
        forbidClick: true,
        message: '正在创建订单'
      })
      createOrder({
        token: localStorage.getItem(SESSION_NAME),
        catid: this.info.pcatid,
        content_id: this.info.pid,
        num: this.count,
        payment: 'H5支付'
      }).then(res => {
        const { data } = res
        this.orderformid = data.orderformid

        toast.message = '正在准备支付'

        upWechatPay({
          token: localStorage.getItem(SESSION_NAME),
          orderformid: this.orderformid
        }).then(res => {
          toast.message = '正在检查支付状态'

          const { data } = res

          localStorage.setItem('tr_pay', true)
          localStorage.setItem('tr_pay_id', this.orderformid)

          this.showPayConfirm()
          this.$toast.clear()

          location.href = data
        }).catch(() => {
          this.$toast.clear()
          this.$toast('检查支付状态失败')
        })
      }).catch(() => {
        this.$toast.clear()
        this.$toast('创建订单失败')
      })
    },
    showPayConfirm() {
      // 表示有支付正在进行
      if (localStorage.getItem('tr_pay')) {
        Dialog.confirm({
          title: '支付确认',
          message: '是否支付完毕'
        }).then(() => {
          checkPayResult({
            token: localStorage.getItem(SESSION_NAME),
            orderformid: localStorage.getItem('tr_pay_id')
          }).then(() => {
            this.$router.push({ name: 'bookdone', query: { id: localStorage.getItem('tr_pay_id') } })
          }).finally(() => {
            localStorage.removeItem('tr_pay')
            localStorage.removeItem('tr_pay_id')
          })
        }).catch(() => {
          localStorage.removeItem('tr_pay')
          localStorage.removeItem('tr_pay_id')
        })
      }
    }
  },
  mounted() {
    this.init()
    this.showPayConfirm()
  },
  computed: {
    bookTimeText() {
      return [(this.info.starttime && this.info.starttime != '0000-00-00 00:00:00') ? this.info.starttime : '即日起', this.info.endtime].join('-')
    },
    total() {
      return this.info.price * this.count * 100
    }
  }
}
</script>

<style lang="scss" scoped>
.book-confirm {
  padding-top: 48px;

  .swiper {
    width: 375px;
    height: 300px;
  }
}
</style>
